.max-w-front {
  max-width: 600px;
}

.front-bg-color {
  // background-color: #f9f9f9;
}

.front-bg-main {
  background-color: #3fc979;
}

.circle-dot-wrap {
  margin-right: auto;
  margin-bottom: 1rem;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  width: 1.1rem;
  height: 1.1rem;
  border: 1px solid #3fc979;
  border-radius: 50%;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: calc(0.25rem - 1px);
  //   left: calc(0.25rem - 1px);
  //   width: 0.8rem;
  //   height: 0.8rem;
  //   background-color: #3fc979;
  //   border-radius: 50%;
  // }

  .inner-dot {
    position: absolute;
    top: calc(0.19rem - 1px);
    left: calc(0.19rem - 1px);
    width: 0.7rem;
    height: 0.7rem;
    background-color: #3fc979;
    border-radius: 50%;
  }
}

.front-tab-wrap {
  display: inline-block;
  margin: 0 0.8rem 0.8rem 0;
  padding: 0.5rem 1rem;
  color: #c5c5c5;
  border: 2px solid #c5c5c5;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &.active {
    color: #3fc979;
    border-color: #3fc979;
  }
}

.react-multi-carousel-list {
  .react-multiple-carousel__arrow {
    // display: none;
  }
}

.react-multiple-carousel__arrow--left {
  left: 0;
}
.react-multiple-carousel__arrow--right {
  right: 0;
}
