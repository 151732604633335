@import "./variable.scss";

.col-1-9 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 11.11%;
}
.col-8-9 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 88.88%;
}

.field {
  margin-bottom: 1.25rem;

  small {
    &.p-error {
      margin-left: 0.7rem;
    }
  }
}

.p-sidebar-left {
  .p-sidebar {
    // top: -1.75rem;
    min-width: 180px;
    width: 180px !important;
    // height: calc(100% - 3.5rem);
    border-width: 0 1px 0 0;

    .p-sidebar-header {
      display: none;

      & + .p-sidebar-content {
        padding: 0 0 1.25rem;
      }
    }
  }
}

.p-sidebar-mask {
  // top: 3.5rem;

  top: 0;
}

.p-menu {
  padding-top: 0 !important;

  .p-menu-list {
    .p-submenu-header {
      font-size: 1.2rem;
    }

    .p-menuitem-link {
      justify-content: end;
      border-right: 2px solid transparent;

      &:focus {
        box-shadow: none;
      }
    }

    .p-menuitem {
      text-indent: 1rem;

      &.menu-active {
        .p-menuitem-link {
          border-right: 2px solid #07d3af;

          .p-menuitem-text {
            font-weight: bold;
            color: #6296e9;
          }
        }
      }
    }
  }
}

.p-panelmenu {
  .p-panelmenu-panel {
    border-bottom: 1px solid #eeeeee;

    .p-panelmenu-header {
      &:hover,
      &.p-highlight {
        opacity: 1;
      }

      & > a {
        &:focus {
          box-shadow: none;
        }
      }

      .p-panelmenu-header-link {
        padding: 0.8rem 1rem;
        font-weight: normal;
        background: #ffffff;
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border: none;
        border-right: 3px solid #ffffff;

        .p-menuitem-text,
        .p-menuitem-icon,
        i {
          font-size: 1.1rem;
          // color: #ffffff;
        }

        &:not(.p-disabled) {
          &:hover {
            background-color: $col_fourth;
            border-color: $col_sub;
            opacity: 1;

            .p-menuitem-text,
            i {
              color: $col_main;
            }
          }
        }
      }
    }

    &.menu-active {
      .p-panelmenu-header {
        .p-panelmenu-header-link {
          background-color: $col_fourth;
          border-color: $col_sub;

          .p-menuitem-text,
          i {
            font-weight: bold;
            color: $col_main;
          }
        }
      }
    }
  }

  .p-panelmenu-content {
    padding: 0;
    background: transparent;
    border: none;

    .p-menuitem {
      .p-menuitem-link {
        // color: #ffffff;
        border-right: 3px solid #ffffff;

        .p-menuitem-text,
        .p-menuitem-icon {
          // color: #ffffff;
        }

        &:not(.p-disabled) {
          &:hover {
            background-color: $col_fourth;
            border-color: $col_sub;
            opacity: 1;

            .p-menuitem-text {
              color: $col_main;
            }
          }
        }
      }

      &.menu-active {
        background-color: $col_fourth;

        .p-menuitem-link {
          border-color: $col_sub;

          .p-menuitem-text {
            font-weight: bold;
            color: $col_main;
          }
        }
      }
    }

    .p-submenu-list {
      padding: 0 !important;

      .p-menuitem {
        // padding-left: 1rem;
      }
    }
  }
}

.p-toast-message {
  .p-toast-message-content {
    align-items: center;

    .p-toast-message-text {
      .p-toast-summary,
      .p-toast-detail {
        white-space: pre-wrap;
      }
    }
  }
}

.p-breadcrumb {
  min-height: 2rem;
  padding: 0.5rem 2px 3px !important;
  background: transparent !important;

  ul {
    li {
      .p-menuitem-link {
        padding: 2px;
        border-radius: 2px;
      }
    }
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    background: transparent;

    .p-tabmenuitem {
      .p-menuitem-link {
        background: transparent;
      }

      &:not(.p-highlight):not(.p-disabled):hover,
      &.p-highlight {
        .p-menuitem-link {
          background: transparent;
        }
      }
    }
  }
}

.p-datatable {
  .p-datatable-table {
    width: 100%;

    .p-datatable-thead {
      tr {
        th {
          // padding: 0.7rem;
          background: #fafafa;

          &.p-frozen-column {
            &.froze-left {
              left: -1px !important;
              border-right: 2px solid #dadada;
            }

            &.froze-right {
              right: -1px !important;
              border-left: 2px solid #dadada;
            }
          }
        }
      }
    }

    .p-datatable-tbody {
      tr {
        // border-bottom: 1px solid #dee2e6;

        &.p-datatable-row-expansion {
          td {
            background-color: #f1f9ff !important;
          }
        }

        td {
          align-items: flex-start !important;
          // padding: 0.7rem;
          width: 100%;
          word-break: break-word;

          &.p-frozen-column {
            z-index: 1;

            &.froze-left {
              left: -1px !important;
              border-right: 2px solid #dadada;
            }

            &.froze-right {
              right: -1px !important;
              border-left: 2px solid #dadada;
            }
          }

          .p-column-title {
            min-width: 15%;
          }
        }

        &:nth-of-type(even) {
          td {
            background-color: #f9f9f9;
          }
        }

        &.p-datatable-emptymessage {
          td {
            width: 100%;
            color: #bb0000;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .p-datatable {
    &:not(.p-datatable-scrollable) {
      .p-datatable-tbody {
        & > tr {
          border-bottom: 1px solid #dee2e6;

          & > td {
            border: none;
          }
        }
      }
    }
  }
}

.p-tooltip {
  z-index: 1152 !important;

  &.bg-dark {
    .p-tooltip-text {
      padding: 0.4rem 0.7rem;
      color: #ffffff;
      background: #656565;
    }
  }

  .p-tooltip-text {
    background: #dddddd;
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: #dddddd;
    }
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: #dddddd;
    }
  }
}

.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0.5rem;
  }
}

.lg-size {
  .p-checkbox {
    width: 2rem;
    height: 2rem;

    &.p-checkbox-checked {
      .p-checkbox-box {
        &.p-disabled {
          background-color: #007bff !important;
          border-color: #007bff !important;
        }
      }
    }

    .p-checkbox-box {
      width: 2rem;
      height: 2rem;

      &.p-disabled {
        opacity: 0.5;

        .p-checkbox-icon {
          // color: #a9a9a9;
        }
      }

      .p-checkbox-icon {
        font-size: 1.5rem;
      }
    }
  }
}

.p-placeholder {
  color: #bbbbbb !important;
}

.p-paginator {
  .p-paginator-current,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    color: $col_main;

    &:not(.p-disabled):not(.p-highlight):hover {
      background: $col_fourth;
      color: $col_main;
    }
  }

  .p-paginator-pages {
    .p-paginator-page {
      color: $col_main;

      &.p-highlight {
        background: $col_main;
        border-color: $col_main;
      }

      &:not(.p-highlight):hover {
        background: $col_fourth;
        color: $col_main;
      }
    }
  }
}

.p-dialog {
  .p-dialog-header-icons {
    position: absolute;
    right: 1rem;
  }
}

@mixin button-setting($color) {
  background: $color;
  border: 1px solid $color;
}
.p-button {
  font-weight: bold;

  @include button-setting($col_main);
  &:enabled {
    &:focus {
      box-shadow: 0 0 0 0.2rem $col_main_l;
    }
    &:hover,
    &:active {
      @include button-setting($col_main_d);
    }
  }

  &.p-button-text {
    color: $col_main;

    &:enabled:hover,
    :not(button):not(a):not(.p-disabled):hover {
      background: $col_fourth;
      color: $col_main;
    }
  }

  &.p-button-sm {
    padding: 0.42rem 0.65625rem;
  }

  &.p-button-main {
    color: #ffffff;
    @include button-setting($col_main);

    &:enabled {
      &:focus {
        box-shadow: 0 0 0 0.2rem $col_main_l;
      }
      &:hover,
      &:active {
        color: #ffffff;
        @include button-setting($col_main_d);
      }
    }
  }

  &.p-button-third {
    color: #ffffff;
    @include button-setting($col_third);

    &:enabled {
      &:focus {
        box-shadow: 0 0 0 0.2rem $col_third_l;
      }
      &:hover,
      &:active {
        color: #ffffff;
        @include button-setting($col_third_d);
      }
    }
  }

  &.p-button-fifth {
    color: #ffffff;
    @include button-setting($col_fifth);

    &:enabled {
      &:focus {
        box-shadow: 0 0 0 0.2rem $col_fifth_l;
      }
      &:hover,
      &:active {
        color: #ffffff;
        @include button-setting($col_fifth_d);
      }
    }
  }

  &.p-button-success {
    @include button-setting($col_green);

    &:enabled {
      &:focus {
        box-shadow: 0 0 0 0.2rem $col_green_l;
      }
      &:hover,
      &:active {
        color: #ffffff;
        @include button-setting($col_green_d);
      }
    }
  }
}

.p-inputnumber {
  .p-inputnumber-button-group {
    .p-inputnumber-button {
      background: $col_fifth;
      border: 1px solid $col_fifth;

      &:enabled {
        &:hover,
        &:active {
          color: #ffffff;
          @include button-setting($col_fifth_d);
        }
      }
    }
  }
}

.p-radiobutton .p-radiobutton-box.p-highlight.p-disabled {
  .p-radiobutton-icon {
    background: #007bff;
  }
}

.p-disabled {
  color: #868686 !important;
  background-color: #eeeeee !important;
  border-color: #ced4da !important;
  cursor: not-allowed !important;
  pointer-events: auto;
}

.p-checkbox {
  &.p-checkbox-disabled {
    cursor: auto;

    .p-checkbox-box {
      .p-checkbox-icon {
        color: #4f7aff;
        font-weight: bold;
      }
    }
  }
}

.p-fluid {
  .p-dropdown {
    width: 100%;
  }
}

.p-calendar {
  .p-button.p-button-icon-only {
    padding: 0;
  }
}

.p-tree {
  padding: 0;

  .p-tree-container {
    .p-treenode {
      padding: 0;
    }
  }
}

.p-inputtext,
.p-treeselect .p-treeselect-label {
  padding: 0.3rem 0.5rem;
}

.p-button,
.p-inputtext,
.p-dropdown,
.p-treeselect {
  border-radius: 0;
}

.p-inputnumber-input,
.p-password-input {
  width: 100%;
}

.rotate-icon {
  .p-button-icon {
    transform: rotate(-90deg);
  }
}
.rotate-icon-x-180 {
  .p-button-icon {
    transform: rotateX(-180deg);
  }
}
.rotate-icon-xy-180 {
  .p-button-icon {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}

.text-lg {
  .p-inputtext {
    font-size: 1.125rem !important;
  }
}
