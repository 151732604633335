@import "./variable.scss";

html {
  height: 100%;
  font-size: 15px;
  font-family: sans-serif;
}

@media screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: #007bff;
}

a,
.fake-link {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

table {
  border-collapse: collapse;

  &.custom-tb {
    tr {
      th,
      td {
        padding: 0.25rem;
        border: 1px solid #ced4da;
      }
    }
  }
}

.white-space-prewrap {
  white-space: pre-wrap;
}

.min-h-12rem {
  min-height: 12rem;
}

.-mt-1px {
  margin-top: -1px;
}

.pb-100 {
  padding-bottom: 100%;
}
.pb-70 {
  padding-bottom: 70%;
}
.pb-50 {
  padding-bottom: 50%;
}
.w-0 {
  width: 0;
}

.text-main {
  color: $col_main;
}

.text-main-d {
  color: $col_main_d;
}

.text-sub {
  color: $col_sub;
}

.text-fifth {
  color: $col_fifth;
}

.text-shadow-1 {
  text-shadow: #cccccc 0.1rem 0.2rem 0.2rem;
}

.text-shadow-2 {
  position: relative;

  &::before {
    content: attr(content);
    position: absolute;
    inset: 0;
    transform: rotatex(180deg) translatey(15px);
    transform-origin: 50% 100%;
    z-index: -1;

    transform: rotatex(50deg) translatey(-5px) skew(50deg) translatex(3px)
      scaleY(0.6);
    background: #a8a8a8;
    color: transparent;
    background-clip: text;
    mask: linear-gradient(transparent 0%, rgba(255, 255, 255, 1) 80%);
    filter: blur(1px);
  }
}

.word-break {
  word-wrap: break-word;
}

.bg-main {
  background-color: $col_main;
}
.bg-main-d {
  background-color: $col_main_d;
}
.bg-sub {
  background-color: $col_sub;
}
.bg-third {
  background-color: $col_third;
}
.bg-fourth {
  background-color: $col_fourth;
}
.bg-fifth {
  background-color: $col_fifth;
}
.bg-tb-title {
  background-color: $col_tb_title;
}
.bg-tb-subtitle {
  background-color: $col_tb_subtitle;
}
.bg-tb-thirdtitle {
  background-color: $col_tb_thirdtitle;
}
.bg-tb-thirdtitle2 {
  background-color: $col_tb_thirdtitle_2;
}
.bg-tb-fourthtitle {
  background-color: $col_tb_fourthtitle;
}
.bg-tb-fifthtitle {
  background-color: $col_tb_fifthtitle;
}

.bg-position-80 {
  background-size: 80% !important;
}

.border-main {
  border-color: $col_main;
}
.border-main-d {
  border-color: $col_main_d;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
  border-bottom-style: solid;
}

.shadow {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

::placeholder {
  color: #bbbbbb;
}

.imgClip {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  background-color: #f8d7cd;
}

.table-layout-fixed {
  table-layout: fixed;
}

.table-collapse {
  border-collapse: collapse;
}

/* sort start */
.sort-wrap {
  margin: auto;
  max-width: 30rem;
  width: 90%;

  .sort-item {
    margin: 0.5rem;
    color: #000000;
    border: 1px solid #dee2e6;
  }
}

.sort-item {
  margin-bottom: 0.2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #007bff;
  cursor: grab;
  z-index: 1110;
}
/* sort end */

/* fake table start */
.ques-fake-tb-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1px 1.5rem 1px;
  width: 100%;
  font-size: 1.1rem;

  .tb-item {
    display: flex;
    width: calc(50% - 0.5px);
  }

  .tb-cell {
    margin: -1px 0 0 -1px;
    padding: $p_cell;
    font-weight: normal;
    word-break: break-all;
    background-color: $col_white;
    border: 1px solid $col_border;

    &.main-title {
      font-size: 1.4rem;
      background-color: $col_third;
    }

    &.sub-title {
      margin: 0;
      min-width: 100px;
      width: 15%;
      background-color: $col_sub;
    }

    &.third-title {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px;
      width: 150px;
    }

    .more-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid $col_border;

      .more-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $p_cell;
        width: 200px;
        height: 100%;
        background-color: $col_third;
        border-right: 1px solid $col_border;
      }

      .more-content {
        padding: $p_cell;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .ques-fake-tb-wrap {
    .tb-item {
      width: calc(100% - 1px);
    }
  }
}
/* fake table end */

.form-wrap {
  width: 100%;
  font-size: 1.1rem;

  .form-layer-1 {
    .form-body {
      .form-title {
        background-color: $col_third;
      }
    }
  }

  .form-layer-2 {
    margin: 0 -0.5rem 1rem;
    width: calc(100% + 1rem);

    .form-body {
      .form-title {
        background-color: $col_red_l;
      }
    }
  }

  .form-body {
    background-color: $col_white;
    border: 1px solid;

    .form-title {
      padding: 0.5rem;
      text-align: center;
      font-weight: bold;
      border-bottom: 1px solid;
    }

    .form-content {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1rem;
    }
  }
}

.ques-tb-wrap {
  width: 100%;
  font-size: 1.1rem;
  border-collapse: collapse;

  tr {
    th,
    td {
      padding: $p_cell;
      width: 50%;
      font-weight: normal;
      background-color: $col_white;
      border: 1px solid $col_border;

      &.main-title {
        font-size: 1.4rem;
        color: $col_white;
        background-color: $col_fifth;
      }

      &.sub-title {
        min-width: 100px;
        width: 15%;
        background-color: $col_sub;
      }

      &.third-title {
        min-width: 200px;
        width: 200px;
        background-color: $col_third;
        text-align: left;
      }

      .more-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid $col_border;

        .more-title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: $p_cell;
          width: 200px;
          height: 100%;
          background-color: $col_third;
          border-right: 1px solid $col_border;
        }

        .more-content {
          padding: $p_cell;
          width: 100%;
        }
      }
    }
  }
}

@for $i from 1 through 60 {
  .w-#{$i}rem {
    width: $i + rem;
  }
  .h-#{$i}rem {
    height: $i + rem;
  }
  .min-w-#{$i}rem {
    min-width: $i + rem;
  }
  .max-w-#{$i}rem {
    max-width: $i + rem;
  }
  .min-h-#{$i}rem {
    min-height: $i + rem;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
