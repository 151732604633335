@import "../variable.scss";

.upload-wrap {
  .upload-body {
    display: flex;
    flex-wrap: wrap;
  }

  .upload-item {
    position: relative;
    margin-bottom: 0.3rem;
    padding-bottom: 60%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #efefef;
    border: 1px solid $col_border;
    // border-radius: 5px;

    .upload-action {
      position: absolute;
      top: 0.3rem;
      right: 0.3rem;
      z-index: 2;
    }

    .upload-pointer {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      opacity: 0;
      // cursor: pointer;
      transition: all 0.2s ease-in-out;
      z-index: 1;

      &:not(.upload-delete, .upload-local):hover {
        background-color: #0000005e;
        opacity: 1;
      }

      &.upload-local {
        cursor: auto;
      }

      &.upload-delete {
        background-color: #ffffffdb;
        cursor: auto;
        opacity: 1;

        i {
          display: none;
        }
      }

      i {
        font-size: 1.5rem;
        color: $col_white;
      }
    }
  }

  .upload-filename {
    margin-bottom: 1rem;
    text-align: center;
  }
}
