@import "../variable.scss";

.tab-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 100%;
  overflow-x: auto;

  &.tab-underline {
    flex-wrap: wrap;
    justify-content: flex-start;

    .tab-item {
      margin-right: 0;
      min-width: 8rem;
      width: auto;
      border: none;
      border-bottom: 2px solid #eee;
      border-radius: 0;

      &:hover,
      &.tab-active {
        color: $col_main;
        background-color: transparent;
        border-color: $col_main;
      }
    }
  }

  .tab-line {
    margin: 0 0.5rem;
    height: 2rem;
    border-right: 3px solid #000000;
  }

  .tab-item {
    margin-right: 2rem;
    padding: 0.2rem 1.5rem;
    width: 100%;
    line-height: 1.8rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &.tab-active {
      color: #ffffff;
      background-color: $col_main;
      border-color: $col_main;
    }
  }
}

@media screen and (max-width: 1280px) {
  .tab-wrap {
    &.tab-underline {
      .tab-item {
        padding: 0.2rem 1rem;
        min-width: 10%;
      }
    }
  }
}

// @media screen and (max-width: 600px) {
//   .tab-wrap {
//     &.tab-underline {
//       .tab-item {
//         width: 33.33%;
//       }
//     }
//   }
// }
