@import "../variable.scss";

.login-wrap {
  background: linear-gradient(to top right, #c5e1e6, #7c96a8);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .login-inner-wrap {
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px #d3d3d3;
  }
}
