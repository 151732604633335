@import "../variable.scss";

.crumb-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .crumb-item {
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    .crumb-label {
      display: flex;
      align-items: center;
      margin: 0.1rem 0.25rem;
      padding: 0 0.25rem;
      color: $col_text_gray;
      opacity: 1;
      cursor: auto;

      &:first-child {
        margin-left: 0;
      }
    }

    .crumb-link {
      display: flex;
      align-items: center;
      margin: 0.1rem 0.25rem;
      padding: 0 0.25rem;
      color: rgba(38, 143, 255, 1);
      cursor: pointer;
      // transition: all 0.1s ease-in-out;

      &:first-child {
        margin-left: 0;
        // padding-left: 0;
      }

      &:hover {
        opacity: 0.7;
      }

      &:active {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
        border-radius: 3px;
      }
    }
  }
}
